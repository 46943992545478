<template>
  <div class="class-card-wrapper" :class="[cardClass, wrapperBorderClass]">
    <!-- 班级卡片右上角标签 -->
    <div v-if="showRightTopTag && cardRightTagText" class="class-right-top-tag" :class="[cardRightTagClass]">
      <span>{{ cardRightTagText }}</span>
    </div>

    <div v-if="closeIcon" class="icon-close-mycourses" />

    <!-- 班级卡片标识戳 -->
    <div v-if="showBackStamp && cardStampClass" class="class-back-stamp" :class="[cardStampClass]" />

    <!-- 班级基础信息 -->
    <div class="class-info-body" @click="cardClickHandler">
      <template v-if="isTagFirst">
        <slot name="user-info" />
        <!-- 标题模块 -->
        <div class="class-title-container module-section tag-first" :class="[showRightTopTag && cardRightTagText ? 'title-top-space' : '']">
          <div class="class-title">
            <div class="title-name font-semibold text-overflow-ellipsis-2">
              <span v-if="cardData.tag || subjectTag" class="tag-new">{{ cardData.tag || subjectTag }}</span><slot name="class-title">
                {{ cardData.title }}
              </slot>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <!-- 标题模块 -->
        <slot name="user-info" />
        <div class="class-title-container module-section" :class="[showRightTopTag && cardRightTagText ? 'title-top-space' : '']">
          <div class="class-title">
            <div class="title-name font-semibold text-overflow-ellipsis-2">
              <!-- 学练标签 -->
              <span v-if="isLearnPractice" class="tag-new">{{ practicePageConfig.learnPlayTag }}</span>
              <span v-else-if="cardData.tag || subjectTag" class="tag-new">{{ cardData.tag || subjectTag }}</span><slot name="class-title">
                {{ cardData.title }}
              </slot>
            </div>
          </div>
        </div>

        <div v-if="(showLevelDegree && levelDegreeStar) || isShowClassStore || isIncludeTrans" class="diffcult-store-wrapper module-section" :class="{hide: !(showLevelDegree && levelDegreeStar) && !isShowClassStore && platformTag && !isIncludeTrans}">
          <!-- 难度模块 -->
          <template v-if="showLevelDegree && levelDegreeStar">
            <div class="class-diffcult-container normal-text font-medium">
              <div class="diffcult-content">
                <div class="diffcult-label-text">
                  {{ pageConfig.difficulty }}
                </div>
                <Rate icon-size="3.73vw" :rate-level="Number(levelDegreeStar)" />
              </div>
            </div>
          </template>
          <!-- 班级限额模块 -->
          <div v-if="isShowClassStore" class="class-store-container normal-text font-medium">
            {{ $tc('courses.classStore', cardData.store, { store: cardData.store }) }}
          </div>
          <!-- <div v-if="subjectTag" class="class-tag-container normal-text font-medium">
            {{ subjectTag }}
          </div> -->
          <!-- <div v-if="platformTag" class="class-tag-container normal-text font-medium">
            {{ platformTag }}
          </div> -->
          <div v-if="isIncludeTrans" class="class-transfer-container normal-text font-medium">
            {{ locale==='hk' ? transConfig.classTransDiff : transConfig.classTrans }}
          </div>
        </div>
      </template>

      <!-- 时间信息模块 -->
      <div v-if="showDate && dateFormat && !isLearnPractice" class="class-date-container module-section font-medium">
        <div class="class-data-time class-date normal-text text-overflow-ellipsis" :class="{'union-show': !showTeacherInfo}">
          <span v-if="isNewClassCard" class="svg-icon icon-date" /><i v-else class="iconfont icon-take-class-time" /><span><slot name="start-date">{{ startDate||(cardData.spec && cardData.spec.startDate ) || cardData.startDate | resourceDateFormate(timezone, dateFormat) }}</slot></span><span v-if="!isSameDay" class="line">-</span><span v-if="!isSameDay" class="date-text"><slot name="end-date">{{ endDate||(cardData.spec && cardData.spec.endDate)||cardData.endDate | resourceDateFormate(timezone, dateFormat) }}</slot></span>
        </div>
        <div v-if="showTimeDesc && classTimeDesc" class="class-data-time class-time-desc normal-text text-overflow-ellipsis" :class="{'union-show': !showTeacherInfo}">
          <span v-if="isNewClassCard" class="svg-icon icon-clock" />
          <i v-else class="iconfont icon-clock-two" /><span><slot name="time-desc">{{ classTimeDesc }}</slot></span>
        </div>
      </div>

      <!-- 面授地址 -->
      <div v-if="isShowAddress" class="class-location-container module-section font-medium text-overflow-ellipsis normal-text">
        <i class="iconfont icon-location" /><span>{{ getAddress || $t('courses.noAddress') }}</span>
      </div>
      <!-- 聯繫電話 -->
      <div v-if="isShowPhone" class="class-contact-container module-section font-medium normal-text">
        <i class="iconfont icon-telephone" /><span class="lesson-value-text">{{ getPhone }}</span>
      </div>
      <!-- 讲次数模块 -->
      <div v-if="isShowLessonNum" class="class-lesson-num-container normal-text module-section font-medium">
        <span v-if="isNewClassCard" class="svg-icon icon-live-class" />
        <i v-else class="iconfont icon-session" /><span>{{ classNumText }}</span><span class="lesson-value-text"><slot name="lesson-text">{{ lessonText }}</slot></span>
      </div>
      <!-- 学练信息展示 new -->
      <template v-if="isLearnPractice">
        <div class="learn-practice-wrapper font-medium">
          <div class="module-section">
            <i class="iconfont icon-location icon" />
            <div>
              <div>{{ getKeyValue('venueName') }}</div>
              <div v-if="getKeyValue('venueAddr')">
                {{ getKeyValue('venueAddr') }}
              </div>
            </div>
          </div>
          <div class="module-section">
            <i class="iconfont icon-telephone icon" />
            <span>{{ getKeyValue('venuePhone') }}</span>
          </div>
          <div class="module-section">
            <i class="iconfont icon-clock-two icon" />
            <span>{{ practicePageConfig.every }} {{ practicePageConfig.weekMap[getKeyValue('dayOfWeek')] }}
              {{ getKeyValue('startTime') }}-{{ getKeyValue('endTime') }}
            </span>
          </div>
          <div class="module-section">
            <i class="iconfont iconicon-time-limit icon" />
            <span>{{ practicePageConfig.timeLimit }}: {{ getKeyValue('periodAmount') }} {{ practicePageConfig.month }}</span>
          </div>
        </div>
      </template>

      <!-- 教师信息模块 -->
      <template v-if="showTeacherInfo">
        <slot name="teachers-item">
          <template v-if="allTeacherList.length > 0">
            <!-- 教师数量小于等于2 -->
            <template v-if="allTeacherList.length <= 2">
              <div class="class-teacher-container module-section">
                <div v-for="(teacher,index) in allTeacherList" :key="index" class="teacher-item-container">
                  <div class="teacher-avatar">
                    <img class="teacher-avatar-img" width="100%" v-lazy-load :data-src="`${teacher.avatar}?im=Resize=(100,100)`" alt="teacher-avatar">
                  </div>
                  <div class="teacher-title-info">
                    <div class="teacher-name text-overflow-ellipsis">
                      {{ teacher.sysName }}
                    </div>
                    <div class="teacher-title text-overflow-ellipsis">
                      {{ getTeacherType(teacher.teacherType) }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <!-- 教师数量大于2 -->
            <template v-else>
              <div class="class-teacher-container only-avatar module-section">
                <div v-if="isShowTeacherAvatarPlaceholder" class="teacher-avatar-placeholder">
                  <div v-for="i in 3" :key="`teacher-avatar-${i}`" class="circle-point" />
                </div>
                <div v-for="(teacher,index) in teacherListCanShow" :key="index" class="teacher-avatar">
                  <img class="teacher-avatar-img" width="100%" v-lazy-load :data-src="`${teacher.avatar}?im=Resize=(100,100)`" alt="teacher-avatar">
                </div>
              </div>
            </template>
          </template>
        </slot>
      </template>
    </div>
    <slot name="card-body-custom-slot" />
    <slot name="card-bottom">
      <div v-if="showCardBottom" class="card-bottom-wrapper">
        <slot name="card-bottom-body">
          <slot name="card-bottom-left">
            <!-- 活动标签 -->
            <template v-if="showClassStarted && totalLessons > leftLessons">
              <div class="started-lessons-tag">
                {{ $t('courses.lessonStarted', [totalLessons - leftLessons, totalLessons - leftLessons> 1 ? 's' : '']) }}
              </div>
            </template>
          </slot>

          <template v-if="showPriceInfo">
            <div class="price-container font-semibold" :class="{'group-buy-activity': isGroupBuy}">
              <div v-if="isGroupBuy&&groupBuyStyle" class="group-buy" @click="handleGroupBuyClick">
                {{ $t('featureComponents.ClassCard.groupBuy') }}
              </div>
              <template v-if="isClassFree">
                <span v-if="locale==='sg'&&cardData.showOrgPrice!==0" class="discount-price">
                  {{ currency }}{{ (showPerLesson? cardData.perOrgPrice: cardData.showOrgPrice) | priceUnitConvert(minorUnit,isKeepTwoDecimal) }}
                </span>
                <span class="free-price">{{ $t('coursesDetail.free') }}</span>
              </template>
              <template v-else>
                <div class="price-wrap">
                  <!-- 活动价 -->
                  <template v-if="isGroupBuy">
                    <div class="group-price">
                      <span class="discount-price">{{ currency }}{{ showPrice | priceUnitConvert(minorUnit, isKeepTwoDecimal) }}</span>
                      <!-- eslint-disable-next-line max-len -->
                      <span class="sell-price">{{ currency }}{{ (showPerLesson?cardData.activityItem.perShowPrice:cardData.activityItem.showPrice) | priceUnitConvert(minorUnit, isKeepTwoDecimal) }} <span v-if="showPerLesson" class="per-lesson-text">{{ $t('courses.perLesson' ) }}</span></span>
                    </div>
                  </template>
                  <!-- 普通价格 -->
                  <template v-else>
                    <div class="pub">
                      <span v-if="showOriginalPrice && isShowOriginalPrice" class="discount-price">
                        {{ currency }}{{ showOrgPrice | priceUnitConvert(minorUnit,isKeepTwoDecimal) }}
                      </span>
                      <span class="sell-price">
                        {{ currency }}{{ showPrice | priceUnitConvert(minorUnit, isKeepTwoDecimal) }} <span v-if="showPerLesson" class="per-lesson-text">{{ $t('courses.perLesson' ) }}</span>
                      </span>
                    </div>
                  </template>
                  <div class="pub2">
                    <!-- 价格展示是否含税 -->
                    <span v-if="(locale==='sg' || locale==='uk') && showTaxationDesc && !isGroupBuy" class="price-tag tax-desc-tag">
                      <template v-if="cardData.showPriceIsInclude === 1">{{ $t('common.taxExcluded') }}</template>
                      <template v-else>{{ $t('common.taxIncluded') }}</template>
                    </span>
                    <!-- 价格包含课次数 -->
                    <span v-if="locale==='hk' && showLessonNum" class="price-tag sessions-tag">
                      {{ $t('courses.lessonUnitTxt', [leftLessons] ) }}
                    </span>
                  </div>
                </div>
              </template>
            </div>
            <!-- 列表页展示加购 -->
            <template v-if="isCoursesList">
              <div class="btns">
                <AddCart :sku-id="cardData.id" :order-info="cardData" :btn-disabled="btnDisabled">
                  <div :class="{left:true, disabled:btnDisabled}">
                    <img v-show="!btnDisabled" v-lazy-load data-src="~assets/images/icon/cart-add-yellow-fill.svg">
                    <img v-show="btnDisabled" v-lazy-load data-src="~assets/images/icon/cart-add-yellow-fill-disabled.svg">
                  </div>
                </AddCart>
                <Enroll :order-info="cardData" :btn-disabled="enrollDisabled">
                  <div :class="{right:true, disabled:enrollDisabled}">
                    Enroll
                  </div>
                </Enroll>
              </div>
            </template>
          </template>

          <!-- 底部栏右侧操作按钮 -->
          <slot name="card-bottom-right">
            <template v-if="showCardBottomRight && bottomRightBtnText">
              <div class="operate-btn-container">
                <button class="button button-small button-right-operate" :class="bottomRightBtnClass" :disabled="bottomRightBtnDisabled" @click="handleBottomRightBtnClick()">
                  {{ bottomRightBtnText }}
                </button>
              </div>
            </template>
          </slot>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
import get from 'lodash/get'
import Rate from 'components/common/Rate'
import { isOffline, isBigOnline } from 'common/platform.js'
import AddCart from 'components/cart/addCart/index.vue'
import Enroll from 'components/cart/enroll/index.vue'

// mixins
import coursesSa from 'mixins/courses/sa.js';
import classCard from 'mixins/courses/classCard.js';
import 'assets/css/courses.scss'

export default {
  components: { Rate, AddCart, Enroll },
  mixins: [coursesSa, classCard],
  props: {
    isCoursesList: {
      default: false,
      type: Boolean,
    },
    isTagFirst: {
      default: false,
      type: Boolean,
    },
    // 班级卡片信息
    cardData: {
      default: () => ({}),
      type: Object,
    },
    // 订单类型
    orderCategory: {
      default: -1,
      type: Number,
    },
    // 卡片是否可点击
    cardClickable: {
      default: true,
      type: Boolean,
    },
    // 卡片点击跳转链接
    cardLink: {
      default: '',
      type: [String, Object],
    },
    // 卡片跳转链接是否新开窗口
    cardLinkNewWindow: {
      default: false,
      type: Boolean,
    },
    // 班级卡片类名
    cardClass: {
      default: 'common-card',
      type: String,
    },
    // 卡片标识类型
    tagType: {
      default: 0,
      type: Number,
    },
    // 是否显示右上角标签
    showRightTopTag: {
      default: false,
      type: Boolean,
    },
    // 右上角标签
    rightTopTag: {
      default: '',
      type: String,
    },
    // 是否显示标识戳
    showBackStamp: {
      default: false,
      type: Boolean,
    },
    // 是否展示时间
    showDate: {
      default: true,
      type: Boolean,
    },
    // 开始日期
    startDate: {
      default: '',
      type: String,
    },
    // 结束日期
    endDate: {
      default: '',
      type: String,
    },
    // 是否展示时间描述
    showTimeDesc: {
      default: true,
      type: Boolean,
    },
    // 是否展示难度
    showLevelDegree: {
      default: true,
      type: Boolean,
    },
    // 是否展示课次数
    showLessonNum: {
      default: true,
      type: Boolean,
    },
    // 是否展示聯繫電話
    showVenuePhone: {
      default: false,
      type: Boolean,
    },
    // 总讲次数
    totalLessons: {
      default: 0,
      type: Number,
    },
    // 剩余讲次数
    leftLessons: {
      default: 0,
      type: Number,
    },
    // 是否优先展示总讲次数
    priorityLeftLessons: {
      default: true,
      type: Boolean,
    },
    // 是否显示教师信息
    showTeacherInfo: {
      default: true,
      type: Boolean,
    },
    // 是否展示商品价格信息
    showPriceInfo: {
      default: true,
      type: Boolean,
    },
    // 是否展示商品原价，即划线价
    showOriginalPrice: {
      default: false,
      type: Boolean,
    },
    // 是否展示税费说明
    showTaxationDesc: {
      default: false,
      type: Boolean,
    },
    // 是否含税
    showPriceIsInclude: {
      default: false,
      type: Boolean,
    },
    showPerLesson: {
      default: false,
      type: Boolean,
    },
    // 是否显示卡片底部操作栏
    showCardBottom: {
      default: true,
      type: Boolean,
    },
    // 是否显示左侧模块
    showCardBottomLeft: {
      default: false,
      type: Boolean,
    },
    // 是否显示班级已开课标识
    showClassStarted: {
      default: false,
      type: Boolean,
    },
    // 是否显示右侧模块
    showCardBottomRight: {
      default: false,
      type: Boolean,
    },
    // 是否是团购
    groupBuy: {
      default: () => ({}),
      type: Object,
    },
    // 团购样式
    groupBuyStyle: {
      default: true,
      type: Boolean,
    },
    // 卡片底部右侧按钮文案
    bottomRightBtnText: {
      default: '',
      type: String,
    },
    // 卡片底部右侧按钮是否不可用
    bottomRightBtnDisabled: {
      default: false,
      type: Boolean,
    },
    // 卡片底部右侧按钮样式类
    bottomRightBtnClass: {
      default: () => [],
      type: Array,
    },
    // 卡片曝光埋点事件名称(默认无曝光埋点)
    cardShowLpEventName: {
      default: '',
      type: String,
    },
    // 是否显示上课地址
    showAddressDetail: {
      default: false,
      type: Boolean,
    },
    // 平台类型
    platformType: {
      default: '',
      type: String,
    },
    subPlatformType: {
      default: 0,
      type: Number,
    },
    // 是否显示班级限额
    showClassStore: {
      default: true,
      type: Boolean,
    },
    // 是否显示外层边框
    showWrapperBorder: {
      default: false,
      type: Boolean,
    },
    // 班级卡片来源模式
    // card/list
    coursesMode: {
      default: 'card',
      type: String,
    },
    // 订单类型
    isIncludeTrans: {
      default: false,
      type: Boolean,
    },
    closeIcon: {
      default: false,
      type: Boolean,
    },
    studentId: {
      default: '',
      type: String,
    },
    openWebview: {
      default: false,
      type: Boolean,
    },
    // 是否是学练
    isLearnPractice: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
      clientOptions: null,
      dateFormat: '',
      isKeepTwoDecimal: this.$t('common.isKeepTwoDecimal'),
      pageConfig: this.$t('page.classCard'),
      practicePageConfig: this.$t('featureComponents.page.subscriptionList'),
      transConfig: this.$t('featureComponents.OrderDetailTemplate'),
      isAppJsInject: false,
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.ucenter.isLogin
    },
    btnDisabled() {
      return !['forSale', 'enrolNow', 'enrol'].includes(this.btnStatus);
    },
    enrollDisabled() {
      return !['enrolNow', 'enrol'].includes(this.btnStatus);
    },
    // 是否满班状态
    btnStatus() {
      const { cardData } = this
      const {
        spec: { courseClosed }, sellStore, store, remainSellTime,
      } = cardData
      if (courseClosed) {
        return this.formPage === 'unpaid-courses-list' ? 'unavailable' : 'courseEnded'
      }
      if (remainSellTime > 1000) {
        return 'forSale'
      }
      // 待缴费清单不存在报满状态
      if (store - sellStore <= 0 && this.fromPage !== 'unpaid-courses-list') {
        return 'full'
      }
      if (this.fromPage === 'unpaid-courses-list') {
        return 'enrolNow'
      }
      return 'enrol'
    },
    // 边层边框样式名
    wrapperBorderClass() {
      return this.showWrapperBorder ? 'card-border' : ''
    },
    getAddress() {
      // 详情页显示地址详情， 商城列表卡片显示教学点
      if (this.showAddressDetail) {
        return get(this.cardData, 'spec.platformContent.classRoom.venueAddress', get(this.cardData, 'platformContent.classRoom.venueAddress'))
      }
      return get(this.cardData, 'spec.platformContent.classRoom.venueName', get(this.cardData, 'platformContent.classRoom.venueName'))
    },
    getPhone() {
      return get(this.cardData, 'spec.platformContent.classRoom.venuePhone', get(this.cardData, 'platformContent.classRoom.venuePhone'))
    },
    getPlatform() {
      let { platformType, subPlatformType } = this
      // 不传platformType 时， 从cardData取值
      if (!platformType) {
        platformType = get(this.cardData, 'spec.platformType', this.cardData.platformType)
      }
      if (!subPlatformType) {
        subPlatformType = get(this.cardData, 'spec.subPlatformType', this.cardData.subPlatformType)
      }
      return { platformType, subPlatformType }
    },
    isShowAddress() {
      const { platformType } = this.getPlatform
      return ['hk', 'us'].includes(this.locale) && isOffline(platformType) && this.getAddress !== undefined
    },
    isShowPhone() {
      const { platformType } = this.getPlatform
      return this.showVenuePhone && ['hk', 'us'].includes(this.locale) && isOffline(platformType)
    },
    isShowLessonNum() {
      return this.showLessonNum && !this.isShowAddress
    },
    classNumText() {
      const { platformType } = this.getPlatform
      return isOffline(platformType) ? this.pageConfig.inPerson : this.pageConfig.liveClass
    },
    getSubject() {
      const { platformType, subPlatformType } = this.getPlatform
      const {
        inPerson, bigOnline, smallOnline,
      } = this.pageConfig
      if (isOffline(platformType)) {
        return inPerson
      }
      // 美分也显示面授， 不显示在线大小班
      if (['hk', 'us'].includes(this.locale) && isBigOnline(platformType) && subPlatformType !== undefined) {
        return `${subPlatformType === 0 ? bigOnline : smallOnline}`
      }
      return get(this.cardData, 'spec.subject', '') || get(this.cardData, 'tag', '')
    },
    getHotSignClass() {
      if (this.locale !== 'hk' || ['New', 'Popular', 'Full'].includes(this.rightTopTag)) {
        return this.rightTopTag
      }
      if (this.cardData.remainSellTime > 1000) {
        return 'Sold-s'
      }
      return ''
    },
    isGroupBuy() {
      return Object.keys(this.groupBuy).length > 0
    },
    isShowTeacherAvatarPlaceholder() {
      return this.allTeacherList.length > 5;
    },
    isNewClassCard() {
      return this.cardClass.includes('class-card-simple-style')
    },
    teacherListCanShow() {
      return Array.prototype.slice.call(this.allTeacherList, 0, 5)
    },
    allTeacherList() {
      const { teachers } = this.cardData
      // 会员中心我的订单、我的课程接口数据兼容
      if (teachers) {
        return teachers.map((item) => ({
          avatar: item.avatar,
          teacherType: item.identityType,
          sysName: item.name,
        }))
      }
      if (!this.cardData.spec) return []
      const { tutorList = [], teacherList = [] } = this.cardData.spec || {}
      return teacherList.concat(tutorList)
    },
    locale() {
      return this.$store.state.locale
    },
    timezone() {
      return this.$store.state.timezone
    },
    currency() {
      return this.$store.state.currency.symbol
    },
    minorUnit() {
      return this.$store.state.currency.minorUnit;
    },
    isSameDay() {
      if (this.startDate && this.endDate) {
        return this.startDate.split(' ')[0] === String(this.endDate.split(' ')[0])
      }
      if (!this.cardData.spec?.startDate) return true;
      if (this.cardData.startDate || this.cardData.endDate) {
        return String(this.cardData.startDate.split(' ')[0]) === String(this.cardData.endDate.split(' ')[0])
      }
      return this.cardData.spec && String(this.cardData.spec.startDate.split(' ')[0]) === this.cardData.spec && String(this.cardData.spec.endDate.split(' ')[0])
    },
    lessonText() {
      if (this.priorityLeftLessons) {
        // 优先展示剩余课次时
        if (Number(this.leftLessons) === 0) return this.$t('courses.courseEnded')
        return this.$t('courses.lessonLeft', [this.totalLessons - this.leftLessons > 0 ? this.$t('courses.leftTxt') : '', this.leftLessons, this.leftLessons > 1 && this.locale !== 'hk' && this.locale !== 'jp' && this.locale !== 'kr' ? 's' : ''])
      }
      // 优先展示总讲次时
      if (!this.totalLessons) return ''
      return `${this.totalLessons} ${this.$t('courses.lessonUnit')}${this.totalLessons > 1 && this.locale !== 'hk' && this.locale !== 'jp' && this.locale !== 'kr' ? 's' : ''}`
    },
    // 是否显示班级限额字段
    isShowClassStore() {
      return this.showClassStore && this.cardData && this.cardData.store > 0
    },
    // 价格判断逻辑
    isClassFree() {
      // eslint-disable-next-line eqeqeq
      return this.cardData.showPrice == 0
    },
    isShowOriginalPrice() {
      let diffVal = this.cardData.showOrgPrice - this.cardData.showPrice
      if (Number.isNaN(diffVal)) diffVal = this.cardData.orgPrice - this.cardData.sellPrice
      if (Number.isNaN(diffVal)) return false
      return diffVal > 0
    },
    showOrgPrice() {
      if (this.showPerLesson) return this.cardData.perOrgPrice || this.cardData.showOrgPrice || this.cardData.orgPrice
      return this.cardData.showOrgPrice || this.cardData.orgPrice
    },
    showPrice() {
      if (this.showPerLesson) return this.cardData.perShowPrice || this.cardData.showPrice || this.cardData.sellPrice
      return this.cardData.showPrice || this.cardData.sellPrice
    },
    // 难度星级
    levelDegreeStar() {
      const { spec = {} } = this.cardData
      return spec.levelDegreeStar || this.cardData.levelDegreeStar || 0
    },
  },
  async mounted() {
    this.classCardShow()
    this.isAppJsInject = process.client ? !!window.JsInjectionActivity : false;
    const clientOptions = JSON.parse(window.localStorage.getItem('clientOptions'))
    if (!clientOptions) {
      await this.$store.dispatch('ucenter/getClientOptions', { $axios: this.$axios });
    }
    this.clientOptions = JSON.parse(window.localStorage.getItem('clientOptions'))
    const dateFormatMap = {
      UK: 'DD/MM/YYYY',
      FR: 'DD/MM/YYYY',
      JP: 'YYYY/MM/DD',
    }
    this.dateFormat = dateFormatMap[this.clientOptions.dateFormat] || 'MM/DD/YYYY'
  },
  methods: {
    getKeyValue(key) {
      let key2 = key
      if (key === 'venueAddr') {
        key2 = 'venueAddress'
      }
      const resVal = this.cardData[key2]
      if (resVal === undefined) {
        return this.cardData.spec?.[key]
      }
      return resVal
    },
    getTeacherType(type) {
      if (Number(type) === 1) {
        return this.$store.state.teacher.lecturerTitle
      }
      return this.$store.state.teacher.assistantTitle
    },
    // 卡片底部右侧按钮点击事件
    handleBottomRightBtnClick() {
      this.$emit('card-bottom-right-click')
    },
    // 点击卡片跳转至班级详情页
    cardClickHandler() {
      if (!this.cardClickable) return
      this.classCardClick()
      const cardLink = this.cardLink ? this.cardLink : `/courses/detail/${this.cardData.id || ''}${this.studentId ? `?studentId=${this.studentId}` : ''}`
      // 判断是否新开窗口
      if (this.cardLinkNewWindow) {
        window.open(cardLink)
      } else if (this.isAppJsInject) {
        this.$transformLocalPath(cardLink, this.openWebview, this.$router)
      } else {
        this.$router.push(cardLink)
      }
      this.$emit('card-click')
    },
    // 神策埋点-班级卡片曝光
    classCardShow() {
      const fromSourcePage = this.getClassFrom(this.$route.name)
      if (fromSourcePage) {
        this.Sensors.track('class_card_show', this.getClassSaData(fromSourcePage, this.cardData, this.coursesMode))
      }
    },
    // 神策埋点-班级卡片点击
    classCardClick() {
      const fromSourcePage = this.getClassFrom(this.$route.name)
      if (fromSourcePage) {
        this.Sensors.track('class_card_click', this.getClassSaData(fromSourcePage, this.cardData, this.coursesMode))
      }
    },
    // 神策埋点-班级卡片通用参数
    getClassCardSaData(source) {
      // 主讲老师
      const teacherList = this.cardData.spec.teacherList || ''
      let teacherNames = ''
      teacherList.forEach((teacher) => {
        teacherNames += teacher.sysName
      });
      // 辅导老师
      const tutorList = this.cardData.spec.tutorList || ''
      let tutorNames = ''
      tutorList.forEach((teacher) => {
        tutorNames += teacher.sysName
      });
      const saData = {
        course_name: this.cardData.title,
        course_id: this.cardData.spec.courseId,
        class_id: this.cardData.spec.clazzId,
        year: this.cardData.spec.year,
        semester: this.cardData.spec.term,
        subject: this.cardData.spec.subject,
        grade: this.cardData.spec.gradeName,
        course_level: this.cardData.spec.levelDegreeName,
        course_capacity: this.cardData.store,
        course_category: this.cardData.spec.courseType,
        course_type: this.cardData.spec.platformType,
        content_teacher: teacherNames,
        content_teacher_ids_str: this.cardData.spec.teacherIds.join() || '',
        local_teacher: tutorNames,
        local_teacher_ids_str: this.cardData.spec.tutorIds.join() || '',
        previous_source: source,
      }
      return saData
    },
    // 神策埋点-班级卡片曝光来源
    getClassCardFrom() {
      const fromSource = this.$route.name
      console.log('fromSource', fromSource)
      switch (fromSource) {
        case 'courses':
          return 'courses页';
        case 'courses-list':
          return '班级列表页';
        case 'courses-available-classes-courseId':
          return '班级列表页';
        case 'free-trial-class':
          return 'free trial页面';
        case 'teachers-detail-teacherId':
          return 'teacher detail页面';
        case 'my-wish-list':
          return '收藏夹';
        case 'my-unpaid-courses-list':
          return '待缴费清单';
        default: return '';
      }
    },
    // 设置班级卡片埋点数据
    setClassCardLpParams(cardData) {
      const params = {
        subject_id: cardData.spec.subjectId || '',
        grade_id: cardData.spec.grade || '',
        course_type: cardData.spec.courseType || '',
        term_id: cardData.spec.term || '',
        main_teacher_id: cardData.spec.teacherIds.join() || '',
        sell_price: cardData.sellPrice || '',
        original_price: cardData.orgPrice || '',
        currency_code: cardData.spec.subjectId || '',
      }
      return params
    },
    handleGroupBuyClick() {
      this.$emit('group-buy-click')
    },
  },
}
</script>

<style lang="scss" scoped>
.learn-practice-wrapper {
  display: block;
}

.btns {
  width: 135px;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;

  .left,
  .right {
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
    font-weight: 600;
  }

  .left {
    width: 50px;
    background: #fff;
    color: #ff9f0a;
    border-radius: 8px 0 0 8px;
    border: 1px solid #ff9f0a;

    img {
      position: relative;
      top: -1px;
    }

    &.disabled {
      color: #ffebce;
      border: 1px solid #ffebce;
      cursor: no-drop;
      background: #fff;
    }
  }

  .right {
    width: 85px;
    border-radius: 0 8px 8px 0;
    background: #ff9f0a;
    color: #fff;

    &.disabled,
    &.disabled a {
      background: #ffebce;
      cursor: no-drop;
    }
  }
}

.tag-first {
  &.class-title-container .class-title {
    padding-left: 16px;
  }
}

.icon-close-mycourses {
  width: 60px;
  height: 60px;
  position: absolute;
  background: url("~assets/images/my-courses/icon_close.png") 0 0/ 100% auto no-repeat;
  bottom: 0;
  right: 0;
}

.bottom-price-status-wrapper {
  position: relative;
  margin-bottom: 68px;

  .card-bottom {
    margin-top: px2vw(2);
    height: px2vw(128);
    padding: px2vw(32);
    border-radius: px2vw(20);
    background: #fff;
  }
}

.tag-new {
  padding: 0 4px;
  font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background: var(--txt-color-link);
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 0 0;
}

.chat-class-card {
  .class-title-container {
    padding: 20px 12px 16px;
  }

  .diffcult-store-wrapper {
    padding: 2px 12px;

    .class-diffcult-container,
    .class-store-container {
      margin-right: 8px;
    }

    .class-store-container {
      padding: 0 6px;
    }
  }

  .class-date-container {
    padding: 0 12px;
  }

  .class-lesson-num-container {
    padding: 2px 12px;
  }

  .class-teacher-container {
    padding: 14px 12px 0;

    .teacher-item-container {
      .teacher-avatar {
        width: 35px;
        height: 35px;

        .teacher-avatar-img {
          width: 35px;
        }
      }

      .teacher-title-info {
        .teacher-name {
          margin-bottom: 0;
        }
      }
    }
  }

  .card-bottom-wrapper {
    padding: 15px 12px 16px;

    &::before {
      width: auto;
      left: 12px;
      right: 12px;
    }
  }
}

.union-show {
  margin-top: 10px !important;
}

.class-info-body {
  position: relative;
}
</style>
