<template>
  <div>
    <div class="header">
      <Header v-if="pageType != 3" :show-back="isBack" :back-url="backUrl" :title="title" />
      <div v-for="item in btnComponentList" :key="item.key">
        <ButtonGroupFixed
          :component-name="item.content.componentName"
          :component-type="item.content.componentType"
          :component-title="item.content.componentTitle"
          :module-id="item.id"
          v-bind="item.content.componentData"
        />
      </div>
    </div>
    <DynamicComponent v-if="!!componentList.length" :style="handleStyles(componentList)" :locale="locale" :component-list="componentList" />
    <Footer v-if="pageType != 3" />
    <UseChatRobot v-if="isShowChat && isChatPath && !isApp" />
  </div>
</template>

<script>
import DynamicComponent from '@/components/global/DynamicComponents/touch'
import Header from '@/components/global/FeatureComponents/Header/touch'
import Footer from '@/components/global/FeatureComponents/Footer/touch'
import ButtonGroupFixed from '@/components/global/DynamicComponents/ButtonGroupFixed/touch'
import UseChatRobot from '@/components/global/FeatureComponents/ChatRobot/UseChatRobot/touch.vue'
// import { createScript } from '../../libs/token'
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';

const throttle = require('lodash/throttle');

export default {
  middleware: ['queryCustomPage'],
  components: {
    Header,
    Footer,
    DynamicComponent,
    ButtonGroupFixed,
    UseChatRobot,
  },
  data() {
    const { locale } = this.$store.state
    return {
      locale,
      // 滑动展示chat入口
      isShowChat: false,
    }
  },
  computed: {
    isApp() {
      return !!window.JsInjectionActivity
    },
    backUrl() {
      return this.$route.params.backUrl || ''
    },
    isBack() {
      return !!this.$route.params.backUrl
    },
    pageType() {
      return this.$store.state.customPage.type
    },
    title() {
      return this.$route.params.title
    },
    componentList() {
      // return this.$store.state.customPage.pageModuleList
      return this.$store.state.customPage.pageModuleList.filter((item) => item.type !== 49)
    },
    btnComponentList() {
      let componentList = this.$store.state.customPage.pageModuleList.filter((item) => item.type === 49)
      componentList = componentList.map((item) => {
        const data = {}
        try {
          const v = JSON.parse(item.content)
          data.content = v
        } catch (err) {
          console.log('err is ', err)
        }

        return { ...item, ...data }
      })
      return componentList
    },
    isChatPath() {
      const pathList = ['/', '/grades-prek-2', '/grades-3-5', '/middle-school', '/ap', '/sat', '/trial-classes', '/evaluation']
      return (this.locale === 'us' || this.locale === 'cn') && pathList.includes(this.$route.path)
    },
  },
  mounted() {
    // const { verifyKey } = process.env;
    // gRecaptcha.createScript.call(this, verifyKey)
    this.getTrackData()
    this.addTouchEvent();
  },
  destroyed() {
    this.removeTouchEvent();
  },
  methods: {
    handleStyles(list = []) {
      const componentList = list.filter((item) => item.type !== 49)
      const styles = {
        paddingBottom: '5.33333vw',
        background: '#fff',
      }
      if (!componentList.length) {
        return {
          ...styles,
        }
      }
      const firstComponent = componentList[0]
      if (!firstComponent.content) {
        return {
          ...styles,
        }
      }
      const contentData = JSON.parse(firstComponent.content)
      const styleAttr = {}
      if (!contentData.componentName.includes('Banner')) {
        styleAttr.paddingTop = '4.26667vw'
      }
      return {
        ...styleAttr,
        ...styles,
      }
    },
    getTrackData() {
      const sourceType = this.$route.query.source_type || window.sessionStorage.getItem('source_type') || ''
      const sourceId = this.$route.query.source_id || window.sessionStorage.getItem('source_id') || ''
      this.Sensors.track('component_page_pv', {
        source_id: sourceId,
        source_type: sourceType,
      })
    },
    addTouchEvent() {
      // window.addEventListener('touchmove', this.watchTouch, false);
      window.addEventListener('touchmove', this.watchTouch, false);
    },
    // 移除页面滚动事件
    removeTouchEvent() {
      window.removeEventListener('touchmove', this.watchTouch, false);
    },
    watchTouch: throttle(function watchTouch() {
      this.isShowChat = true
    }),
  },
  head() {
    if (this.locale !== 'ae') return {}
    return {
      link: [{
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: '/edulogo.ico',
      }],
    }
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 101;
}
</style>
<style>
.grecaptcha-badge { visibility: hidden; }
</style>
